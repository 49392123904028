@import "../helpers/helpers.scss";

.header-logo {
  max-width: 220px;

  &-img {
    height: 60px;
  }
}

@include m-max($sm) {
  .header-logo-img {
    max-width: 155px;
    object-fit: contain;
    //margin-top: -10px;
  }
}

.header .header-logo-img {
  max-width: 150px;
  padding: 0 20px;
  @include m-min($sm) {
    max-width: none;
    padding: 0;
  }
  @include m-min($lg) {
    height: 60px;
    margin-left: 0;
  }
}