@import '../helpers/helpers.scss';

.header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  border-bottom:1px solid $gray75;
  background:$white;

  .header-logo-img {
    padding: 5px 10px;
    margin-left: 0;
  }

  &_inner_grid {
    //justify-content: space-between;
    //flex-wrap: wrap;
  }

  .nav-menu_item-logout a{
    cursor: pointer;
  }

  .nav-menu,
  &_menu_login-link,
  &-inner_grid,
  &_menu,
  &_menu > button,
  &_inner_grid > .nav-menu > li a {
    display: flex;
  }

  .nav-menu,
  &_menu_login-link {
    align-items: center;
  }

  &_inner_grid > .nav-menu {
    flex: 1;
    justify-content: center;
    padding: 0 15px;
  }

  &_menu,
  &_inner_grid > .nav-menu {
    > button,
    > li a {
      justify-content: center;
      padding: 10px 12px;
      color: $gray400;
      font-family: $font;
      font-size: $defsz;

      &.active,
      &:hover {
        color: $brand;
        text-decoration: unset;
      }
    }
  }

  .header_inner_grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    a.logo {
      margin-left: 0;
      grid-column-start: 2;
      grid-column-end: 9;
    }
    .mobile-hamburger-menu {
      order: -1;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .icon-cont {
      display: flex;
      justify-content: flex-end;
      grid-column-start: 9;
      grid-column-end: 13;
    }

    @include m-min($sm) {
      a.logo {
        grid-column-start: 2;
        grid-column-end: 9;
      }
    }

    @include m-min($lg) {
      a.logo {
        margin-left: 0;
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .nav-menu {
        grid-column-start: 4;
        grid-column-end: 11;
      }
      .icon-cont {
        grid-column-start: 11;
        grid-column-end: 13;
      }
    }


  }
}

@include m-max($xs) {
  .mobile-basket-price {
    display:none;
  }
  .nav-linked-in {
    font-size:14px;
    margin-right:10px;
  }
}

@include m-min($lg) {
  .header {
    .logout-btn {
      max-width: 150px;
    }
  }
}

@include m-max($lg) {
  .header_inner_grid > .nav-menu {
    display: none;
  }
}