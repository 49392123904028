@import '../helpers/helpers.scss';
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  position: relative;
  color: $gray400;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2;
  font-size: 16px;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: all $trans;

  &:hover {
    color: $brand;
    text-decoration: underline;
    transition: all $trans;
  }
}

input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  box-shadow: none; // none;
  outline: none;
}

button,
a.main-button,
.react-select__control,
textarea,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="search"] {
  font-family: 'Montserrat', sans-serif;
  border: 1px solid transparent;
  min-height: 38px;
  background: $gray75;
  transition: all $trans;
  border-radius: $bradius;

  &:focus,
  &:active,
  &:hover {
    border-color: $brand;
    transition: all $trans;
  }
}
textarea {
  color:$gray400;
}

button,
textarea,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="search"] {
  padding: 8px 10px;
  width: 100%;
  font-size:16px;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
a.main-button {
  background: transparent;
  padding: 0;
  border: 0;
  margin: 0;

  &:not(.icon) {
    @include gradientBtn;
    cursor: pointer;
    color: $white;
    transition: all $trans;
    background-size: 300% 100%;
    opacity: 0.9;
    border-color: transparent;
    font-size: $defsz;
    padding: 8px 10px 7px;
    font-weight: $bold600;

    &:hover {
      background-position: 100% 0;
      opacity: 1;
    }
  }
}

.page-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font;
  font-weight: $bold500;
  color: $gray400;
  text-align: center;
  margin-bottom: 30px;

  @include m-min($lg) {
    margin-bottom: 50px;
  }
}

h1 {
  font-size: 26px;
  line-height: 1.1;

  @include m-min($md) {
    font-size: 32px;
  }
}

h2 {
  font-size: 22px;
  line-height: 1.1;

  @include m-min($md) {
    font-size: 24px;
  }
}

h3 {
  font-size: 18px;
  line-height: 1.1;

  @include m-min($md) {
    font-size: 18px;
  }
}

h4,
h5,
h6 {
  font-size: 14px;
  line-height: 1.1;

  @include m-min($md) {
    font-size: 16px;
  }
}

table {
  border-radius: 0px;
  overflow: hidden;
}

.privacy-page p, .terms-conditions-page p {
  font-family: 'Montserrat', sans-serif;
  padding-bottom:15px;
}

.react-datepicker__month-container {
  width:320px;
}

