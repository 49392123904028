@import './helpers/helpers.scss';

.App {
    @include flex-col;
    height: 100%;
    justify-content: space-between;
}

.page {
    display: flex;
    align-items: center;
    height: 100%;
}

