@import "../helpers/helpers.scss";

.thank-you-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  font-family: $font;

  h2 {
    border-bottom: 1px solid $gray400;
    width: 100%;
    padding-bottom: 20px;
  }

  p {
    text-align: center;
    line-height: 30px;
  }

  .order-number {
    color: $brand;
  }
}