@import '../helpers/helpers.scss';

.customer-profile {
  background:$white;
  padding: 0;
  position:fixed;
  top:90px;
  left:0;
  width:100%;
  border-bottom:1px solid $gray75;
  z-index:9;
  color:white;
  min-height:40px;
  .icon-cont {
    margin-left:auto;
    height:30px;
    @include m-max($sm) {
      display:none;
    }
  }

  .svg-inline--fa {
    color:$gray400!important;
    top: -10px;
    position: relative;
  }
  .container {
    display:flex;
    padding:0;
    .admin {
      display:flex;
      min-width: 380px;
      @include m-max($sm) {width:100%; min-width: revert;}
    }
    .credit {
      line-height: 40px;
      color: $gray400;
      @include m-max($sm) {
        text-align: center;
        background: #efefef;
      }
    }
    .react-select-custom {
      width: 100%;
      max-width:380px;
      @include m-max($sm) {
        max-width: calc(100% - 50px);
        &:only-child {
          max-width: revert;
        }
      }
      font-size:16px;
      .react-select__control {
        border-color:revert;
      }
      .react-select {
     &__value-container {
       justify-content: start;
     }

        &__option {
          color: $gray400;
        }

        &__option--is-focused,
        &__option--is-selected {
          background: $brand;
          color: $white;
          cursor: pointer;
        }
      }
    }
    > span {
      line-height:30px;
      padding-right:10px;
    }
  }
}

.app-settings {
  a {
    display:inline-block;
    width: 38px;
    height:40px;
    background: $gray75;
    margin-left: 15px;
    margin-right:5px;
    text-align:center;
    @include m-max($sm) {
      margin-left: 0;
    }
      &:hover {
        background:$gray100;
      }

    .svg-inline--fa {
      top: 9px;
      font-size: 20px;
    }
  }

}