@import "../helpers/helpers.scss";

.processing-page {
  display:block;
.container {
  width:100%;
  padding:0;
}
  .processing-bar {
    position:absolute;
    top:calc(50vh - 150px);
    width:100%;
    margin:0 -15px;
    background: $gray75;
    text-align: center;
    p {
      line-height: 50px;
    }
  }
}