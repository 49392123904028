@import "../helpers/helpers";

.page.account-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .transactions__field .error {
    border: 1px solid $error;
  }

  .transaction-list .item {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
}


.orders__search-account  h2 {
  margin: 30px auto 5px auto;
}

.page.orders-page.account-page .orders__wrapper .orders__list_header,
.page.orders-page.account-page .orders__wrapper .orders__list_row {
  grid-template-columns: repeat(7, 1fr);
}

@include m-max($md) {
  .page.orders-page.account-page .orders__wrapper .orders__list_header,
  .page.orders-page.account-page .orders__wrapper .orders__list_row {
    grid-template-columns: repeat(1, 1fr);
  }
}

@include m-min($md) {
  .orders {
    &__search-account {
      width: 100%;

      h2 {
        margin: 0 auto 5px 0;
      }
    }
  }
}


.balance {
  width: 100%;
  padding:30px 25% 60px 25%;
  margin-bottom:30px;
  border-bottom:1px solid $gray100;

  input {
    float:left;
    width:50%;
  }
  button {
    width:50%;
    float:right;
  }
}
.credit {
  display: flex;
  width:100%;
  border:10px solid $brand-hover;
  margin-bottom:0px;

  .col-4 {
    width: 33.3333%;
    display: flex;
    .title {
      padding:5px 0 5px 30px;
      width:50%;
      background: $brand-hover;
      color:white;
      box-sizing: border-box;
    }
    .value {
      width:50%;
      box-sizing: border-box;
      padding:5px;
    }
  }
}

.balance_input, .balance_submit {
  width:50%;
}

