@import "../helpers/helpers.scss";

.mobile-hamburger-menu .nav-menu {

  &_item {
    width: 100%;

    &:last-of-type {
      a::before,
      a::after {
        display: none;
      }
    }

    a {
      position: relative;
      padding: 15px 7px;
      text-align: center;
      font-size: 16px;
      font-family: $font;
      cursor: pointer;
      overflow: hidden;
    }

    a::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $gray100;
      transition: all $trans;
    }

    a::after {
      position: absolute;
      bottom: 0;
      left: -100%;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $brand;
      transition: all $trans;
      opacity: 0;
    }

    a:hover {
      text-decoration: none;

      &::after {
        left: 0;
        opacity: 1;
      }
    }
  }
}

  @include m-max($md) {
    .header .nav-menu_item-logout {
      border-bottom:1px solid $gray100;
    }
  }

  .my-rep-container-content {
    font-family: $font;
    display: block;
    width:700px;
    height:330px;
    border-radius: 0;
    line-height: 21px;
    padding:0;
    @include m-max($md) {
      max-width: 80vw;
      }
    @include m-max($sm) {
      max-height: 90vh;
      height:auto;
      max-width:300px;
    }
    .my-rep-content {
      width:100%;
      display:flex;
      flex-direction: row;
      padding:30px;
      @include m-max($sm) {
        flex-direction: column;
      }
    }
    .my-rep-details {

        text-align: left;

      h2 {
        text-align: left;
      }
      ul {
        li {
          line-height:200%;
        }
      }
      svg {
        padding-right:10px;
      }
      a {
        color: $brand;
      }
      width:66.6%;
      box-sizing: border-box;
      @include m-max($sm) {
        width:100%;
      }

    }
    .linked-in {
      width:33.3%;
      box-sizing: border-box;
      @include m-max($sm) {
        width:200px;
        text-align:center;
        margin: 20px;
      }
      .badge-base__link {
        display:none;
      }
      .profile-badge {
        display:flex;
        flex-direction: column;
        .profile-badge__cta-btn {
          order:3;
          position:relative;
          top:-45px;
          left:0;
          width:100%;
          text-align: center;
          text-decoration: none;
          line-height:38px;
          background:transparent;
          font-size:0;
          transition: 0.9s;
          border-radius: 0;
          max-width:200px;
          &:hover {
            font-size:20px;
            background:$brand;
            transition: 0.9s;
            color:white;
            opacity:0.9;
            @include m-max($sm) {
              top:-45px;
            }
          }
        }
      }
      .profile-badge__header {
        order:2;
        background:$brand;
        color:white;
        padding:8px 30px 5px;
        margin-top:15px;
        border-radius: 0;
        max-width:200px;
      }
      .profile-badge__content {
        order:1;
        img {
          border-radius: 0;
        }
        .profile-badge__content-profile-name, .profile-badge__content-profile-headline, .profile-badge__content-profile-company-school-info {
          display:none;
        }
      }
    }
  }
.nav-linked-in {
  padding-right:15px;
  cursor: pointer;
  @include m-max($xs) {
    padding-right: 5px;
  }
  svg {
    vertical-align: -32px;
    @include m-max($xs) {
      vertical-align: -28px;
        }
  }
}
