@import '../helpers/helpers';

.user-menu {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: -10px;

  * {
    font-family: $font;
  }

  li,
  a {
    font-size: $fszSm;
  }

  &-icon:not(.only_basket) {
    position: relative;

    &::before {
      content: '';
      display: block;
      background: url(../../assets/user-purle.svg) no-repeat;
      position: absolute;
      height: 33px;
      width: 33px;
      opacity: 0;
      top: 0;
      right: 0;
      background-size: cover;
    }

    &::before,
    img {
      transition: opacity $trans;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      &:not(.only_basket) img {
        opacity: 0;
      }
    }
  }

  &.opened &-icon {
    &::before {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }

  &-icon img {
    width: 33px;
    height: 33px;
    cursor: pointer;
  }

  &-account-link {
    display: flex;
    align-items: center;

    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      transition: all $trans;
    }

    * {
      transition: all $trans;
    }

    &:hover * {
      color: $brand;
    }
  }

  &-close {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;

    &::before {
      content: 'X';
      display: block;
      position: absolute;
      height: 15px;
      width: 15px;
      opacity: 1;
      top: 0;
      right: 0;
      background-size: cover;
    }

    &::before,
    img {
      transition: opacity $trans;
    }


    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        opacity: 0;
      }
    }

    img {
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }

  &-dropdown {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: auto;
    z-index: 999;
    width: 100%;
    opacity: 0;
    background: #ffffff;
    padding: 15px;
    box-shadow: none; // 0 3px 6px #00000029;
    max-width: 500px;
    visibility: hidden;
    max-height: calc(100% - 90px);
    overflow-x: hidden;
    overflow-y: auto;
    right: 90px;

    &::-webkit-scrollbar {
      width:10px;
      background-color: $gray100;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray400;
      -webkit-border-radius: 0;
      border-radius: 0;
    }

    &::after {
      position: absolute;
      top: 0;
      right: -100%;
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: $brand;
      transition: all .5s linear .1s;
    }

    .logout-btn {
      display: block;
      margin: auto;
    }
  }

  &-dropdown_item_busket {
    width: 100%;
  }

  &-dropdown_item {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &.opened &-dropdown {
    visibility: visible;
    opacity: 1;
    transition: all $trans;

    &::after {
      right: 0;
      width: 100%;
    }
  }
}

@include m-max($md) {
  .user-menu {
    &-dropdown {
      right: 0;
      min-width: 320px;
    }
  }
}

@include m-min($lg) {
  .user-menu {
    &-dropdown {
      right: calc((100% - 1170px)/2);
      min-width: 320px;
    }
  }
}

@include m-min($md) {
  .user-menu {
    margin-left: 15px;


    &-dropdown {
      width: max-content;
      top: 90px;
      min-width: 250px;
      max-width: 520px;
    }
  }
}
