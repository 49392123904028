@import "../helpers/helpers.scss";

.product-list {
  &,
  &-wrapper {
    transition: all $trans;
  }

  &-item {
    padding: 20px;
    margin-bottom: 15px;
  }



  &-item_wrapper {
    width: 100%;
  }
}

@include m-min($md) {
  .product-list-item {
    @include flex-def;
  }
}