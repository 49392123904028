@import '../helpers/helpers.scss';

body,
html,
#root{
  height: 100%;
}

.App {
  padding-top: 90px;
}

.error-message, .confirmation-message {
  background: $error;
  color:$white;
  padding: 10px;
  text-align: center;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  box-sizing: border-box;
  @include m-min($sm) {
    margin:0 calc((100vw - 100%) /-2);
    width:100vw;
  }
}
.confirmation-message {
  background: $notice;
}

.flex-def {
  @include flex-def;
}

.flex-row-rev {
  @include flex-row-rev;
}

.flex-col {
  @include flex-col;
}

.flex-col-rev {
  @include flex-col-rev;
}

.flex-center {
  @include flex-center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.container {
  @include container;
}

.row {
  @include row;
}

.clearfix {
  @include clearfix;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff7a;

  img {
    height: 120px;
    transition: .5s;
  }

  &.hide img {
    height: 0;
  }
}

