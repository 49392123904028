@import "../helpers/helpers.scss";

.footer {
  background: $gray50;
  box-shadow: none; // 0 -1px 5px $shadow100;
  padding: 20px 0;

  &-logo_right {
    padding-left: 20px;
  }
  &-logo_left, &-logo_right {
    width: 190px;
    height:auto;
    filter: grayscale(1);
    &:hover {
      filter: revert;
    }
  }
  * {
    font-size: $defsz;
    color: $brand;
    font-family: $font;
  }

  &-bottom_inner_top,
  &-bottom_inner_bottom,
  &-top {
    @include flex-def;
    justify-content: center;
  }

  &-top {
    margin-bottom: 40px;
  }

  &-bottom_inner_top {
    margin-bottom: 10px;

    p {
      color: $gray400;
    }
  }


  &-link {
    display: inline-block;
    padding: 0 10px;
    position: relative;
    border-right:1px solid $gray400;

    &:first-of-type {
      padding-left:0;
    }
    &:last-of-type {
      padding-right:0;
      border-right:none;
    }
  }

  &-terms-conditions-link {
    padding-left: 10px;
  }
}

@include m-min($sm) {
  .footer {
    padding: 50px 0;

    * {
      font-size: 18px;
    }

  }
}