@import "../helpers/helpers.scss";

.admin {
  .admin__note {
    margin: 30px 0;
    padding-bottom: 15px;
    width:100%;
  }

  .admin__form {
    .section {
      padding: 30px;
      background: $gray50;
      margin-bottom:15px;
      border:1px solid $gray75;
    }
    h3 {
      text-align:left;
      font-weight:bold;
      padding-bottom:15px;
    }
    width: 100%;
     p {
       margin-bottom:15px;
     }

    &-label {
      margin-bottom: 10px;
    }
    &-textarea {
      width: 100%;
      height: 200px;
      border: none;
      background: $gray75;
      padding: 10px;
      border-radius: 0;
      resize: none;

      &:active,
      &:focus {
        outline: none;
        border: 1px solid $brand;
      }
    }
  }
}