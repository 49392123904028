@import "../helpers/helpers.scss";

.product-list-filter {
  &.hide {
    display: none;
  }
}

.pagination {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 90px;
  flex-wrap: wrap;

  li {
    cursor: pointer;

    a {
      display: block;
      height: 100%;
      padding: 0 7px;
      width: 100%;
      color: $gray400;
      font-weight: $normalW;
      font-family: $font;
      margin-bottom: -2px;
    }

    &.active {
      align-items: center;
      justify-content: center;

      a {
        color: $brand;
        font-weight: $bold700;
      }
    }

    &:hover a {
      color: $brand;
    }
  }

  * {
    font-size: 16px;
    color: $brand;
    font-weight: $bold600;
  }


  .next,
  .previous {
    font-size:14px;
    position: relative;
    height: 28px;
    width: 28px;
    display: inline-block;
    text-align: center;
    padding: 2px;
    border: 2px solid $brand;
    border-radius: 50%;
    text-indent: -9999px;
    background-size: 55%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all $trans;
    color:$brand;
    &:before {
      font-family: $fontAwesome;
      content: '\f054';
      color: $brand;
      text-indent: 0;
      float: left;
      z-index:-1;
      position:relative;
    }
      &:hover {
        border-color:$brand-hover;
        &:before {
          color: $brand-hover;
        }
      }

  }
  .next {
    &:before {
     content:'\f054';
      padding:3px 0 0 5px;
    }
  }
  .previous {
    &:before {
      content:'\f053';
      padding:3px 0 0 3px;
    }
  }
}

@include m-max($lg) {
  .pagination li {
    display: none;

    &.active {
      display: inline-flex;
    }
  }
}
