@import "../helpers/helpers.scss";

.basket-button {
  order: 1;
  display: flex;
  align-items: center;
}

.mobile-basket-btn > span {
  position:relative;
}
.mobile-basket-btn {
  svg{
    color: #818181;
    position:relative;
    top: 0;
  }
  .fa-wine-bottle {
    height: 26px;
    margin-left:10px;
    margin-right:-10px;
  }
}

.mobile-case-total-items, .mobile-bottle-total-items {
  background: $brand;
  border-radius: 50%;
  overflow: hidden;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -6px;
  right: 8px;
  color: $white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-case-total-items {
 left:-9px;
  top:-12px;
}

.mobile-bottle-total-items {
  left:-9px;
  top:-12px;
}



.mobile-basket {
  * {
    font-family: $font;
  }

  &-btn {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

.fa-bottle-droplet {
  top:-1.5px;
}

    &::before,
    img {
      transition: opacity $trans;
    }

    &.opened,
    &:hover {
      opacity: 0.9;

    }
  }

  &-icon {
    width: 60px;
    height: 32px;

  }

  &-price {
    color: $brand;
    margin-right: 7px;
  }

  &-total-items {
    //background: $brand;
    //border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 30px;
    position: absolute;
    top: -3px;
    right: 20px;
    color: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.desk {
      top: 10px;
    }
  }
}

@include m-min($sm) {
  .basket-button {
    position: relative;
  }
}

@include m-min($lg) {
  .basket-button {
    display: none;
  }
}

.checkout {
  height: 40px;
  margin-top: 10px;
  line-height: 24px;
  margin-left:-10px;
  margin-right:10px;
  &:hover {
    //color:#fff!important;
  }
}