@import "../helpers/helpers.scss";
//
.basket {
  &-items-wrapper {
    .total-block {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;

      .grand-total-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray75;

        h2,
        h3 {
          margin-bottom: 20px;
        }

        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-right: 6em;
        }

        h3 {
          line-height: 1;
          display: inline-flex;
          align-items: center;
          font-size: 26px;
          font-weight: 700;
        }
      }

    }
  }

  &-items--update-cart.disabled {
    @include disabledBtn;
  }
}

.basket-list-item {

  &_wrapper {
    border-top: 1px solid $gray75;
    display: flex;
    padding: 15px 0;
  }

  &_left {
    max-height: 200px;
    display: none;
    @include m-min($sm) {
      display: flex;
      width: 30%;
    }

    img {
      height: 180px;
      width: auto;
    }
  }

  &_right {
    position: relative;
    width: 100%;
    @include m-min($sm) {
      width: 70%;
    }
    @include m-max($md) {
      &__bottom {
        > div {
          &:first-child {
            padding-right: 5%;
          }

          &:nth-child(2) {
            padding-right: 0;
            padding-left: 5%;
          }

          &:last-child {
            padding-left: 0;
          }
        }
      }
    }

    .cart-remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-title-link h2 {
    text-align: left;
    margin-bottom: 15px;
  }

  &_inner {
    display: inline-block;
    width: auto;
    margin-right: 15px;

    svg {
      padding-right: 5px;
    }
  }
}

.basket_inner {
  padding-top: 30px;

  h6 {
    margin-bottom: 10px;
  }

  .price {
    text-align: center;
    padding-bottom: 10px;
    @include m-min($sm) {
      width: 80%;
    }
  }

  label {
    display: block;
    @include m-min($sm) {
      width: 80%;
    }
    text-align: center;
  }

  input[type='number'] {
    text-align: center;
  }
}

.basket-cases, .basket-bottles {
  width: 50%;
  display: inline-block;
  @include m-min($sm) {
    width: 40%;
    .product-list-item__qty-row {
      width: 80%;
    }
  }
}

.basket-refresh {
  @include m-max($md) {
    margin-top: 60px !important;
  }
  @include m-min($sm) {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 20%;
    display: inline-block;
    padding-left: 20px;

    button {
      display: block;
    }
    .update-cart-btn.disabled {
      @include disabledBtn;
    }
  }

}

.cart-remove-icon {
  width: 16px;
  color: $brand;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid $brand;
  border-radius: 50%;
  padding: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: contrast(.1);
  }
}
