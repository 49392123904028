/* TEST FONT */
@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/old-fonts/Montserrat-Regular.woff2") format("woff2"),
  url("../../fonts/old-fonts/Montserrat-Regular.woff") format("woff"),
  url("../../fonts/old-fonts/Montserrat-Regular.eot") format("eot"),
  url("../../fonts/old-fonts/Montserrat-Regular.ttf") format("ttf");
  font-weight: 300 ;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/old-fonts/Montserrat-Medium.woff2") format("woff2"),
  url("../../fonts/old-fonts/Montserrat-Medium.woff") format("woff"),
  url("../../fonts/old-fonts/Montserrat-Medium.eot") format("eot"),
  url("../../fonts/old-fonts/Montserrat-Medium.ttf") format("ttf");
  font-weight: 400 ;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/old-fonts/Montserrat-SemiBold.woff2") format("woff2"),
  url("../../fonts/old-fonts/Montserrat-SemiBold.woff") format("woff"),
  url("../../fonts/old-fonts/Montserrat-SemiBold.eot") format("eot"),
  url("../../fonts/old-fonts/Montserrat-SemiBold.ttf") format("ttf");
  font-weight: 500 ;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/old-fonts/Montserrat-Bold.woff2") format("woff2"),
  url("../../fonts/old-fonts/Montserrat-Bold.woff") format("woff"),
  url("../../fonts/old-fonts/Montserrat-Bold.eot") format("eot"),
  url("../../fonts/old-fonts/Montserrat-Bold.ttf") format("ttf");
  font-weight: 700 ;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/old-fonts/Montserrat-ExtraBold.woff2") format("woff2"),
  url("../../fonts/old-fonts/Montserrat-ExtraBold.woff") format("woff"),
  url("../../fonts/old-fonts/Montserrat-ExtraBold.eot") format("eot"),
  url("../../fonts/old-fonts/Montserrat-ExtraBold.ttf") format("ttf");
  font-weight: 900 ;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../fonts/Quicksand-Medium.eot');
  src: url('../../fonts/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Quicksand-Medium.woff2') format('woff2'),
  url('../../fonts/Quicksand-Medium.woff') format('woff'),
  url('../../fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../fonts/Quicksand-Bold.eot');
  src: url('../../fonts/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Quicksand-Bold.woff2') format('woff2'),
  url('../../fonts/Quicksand-Bold.woff') format('woff'),
  url('../../fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style : normal;
  src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
  src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}