@import "../helpers/helpers";

.login {
  &-logo-img {
      margin: 0 auto 50px;
      width: 60%;
      @include m-min($lg) {
        width: 40%;
      }
  }

  &-page-title {
    font-family: $font;
    margin-bottom: 30px;
    display: block;
    line-height: 1.3;
  }

  &-page {
    display: flex;
    flex-direction: column;
    border:1px solid $gray100;
    justify-content: center;
    padding: 5px;
    margin-top:-100px;
    height:calc(100vh - 100px);
  }

  &-btn {
    max-width: 120px;
  }
}
.trade-account {
  font-size:14px;
  color:$brand;
  padding-top:30px;
  display:block;
  text-align: center;
}


