@import "../helpers/helpers.scss";

.page.basket {
  font-family: $font;
  background-color: unset;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message-empty {
    display: block;
    text-align: center;
  }

  .basket-items {
    width: 100%;

    &--cart-control {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;

      i {
        color: $white;
        margin: 0 0 0 5px;
      }
    }

    &--clear-cart {
      background: $brand;
      max-width: 150px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 20px;
        border: 2px solid;
        border-radius: 50%;
        padding: 3px;
        color: #fff;
        margin-left: 5px;
      }
    }

    &--update-cart {
      max-width: 150px;

      &.refreshed {
        box-shadow: none; // 0 0 5px $black;

        i {
          color: $lightGreen;
        }
      }
    }

    .totals-block-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .checkout-button {
        padding: 10px 20px;
        margin-top: 20px;

        &.disabled {
          @include disabledBtn;
          cursor: not-allowed;
          pointer-events: none;
        }

        &:hover {
          text-decoration: unset;
        }
      }
    }
  }
}
