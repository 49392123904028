@import '../helpers/helpers.scss';

.content {
  position:relative;
  padding-top: 60px;
  @include m-max($sm) {
    padding-top: 100px;
  }
  padding-bottom: 30px;
  flex: 1;
}

.content-page {
  &-title {
    font-size:30px;
  }
  .address {
    margin-bottom:30px;
  }
  p, a {
    display:block;
    line-height:150%;
    margin-bottom:10px;
  }
  ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-bottom:10px;
    li {
      line-height: 125%;
    }
  }
  h4 {
    font-weight:bold;
    text-align: left;
    font-size:20px;
    margin:40px 0 10px;
  }
  a {
    color:$brand;
  }
  .contact {
    margin-top:60px;
  }
}

.confirmation {
  position:relative;
  margin-top:30px;
  text-align: center;
  p {
    padding:30px 0;
  }
}